<template>
  <div>
    <div class="container" v-if="talk">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col">
              <h3 class="fw-bold text-success">{{ talk.name }}</h3>
            </div>
            <div class="col-auto">
              <router-link
                class="btn btn btn-outline-success btn-sm"
                to="/talks"
                ><i class="far fa-arrow-left me-2"></i>Back</router-link
              >
            </div>
          </div>
          <p class="alert alert-light" v-if="talk.description">
            {{ talk.description }}
          </p>
          <div class="row">
            <div class="col-auto">
              <div class="card border-0 shadow-sm bg-light">
                <div class="card-body text-center">
                  <div class="mb-4">
                    <i class="far fa-file fa-4x text-success"></i>
                  </div>
                  <a :href="talk.url" class="btn btn-success" target="_blank"
                    >Download</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      talk: null,
    };
  },
  methods: {
    fetchTalk() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/talks/" + this.$route.params.id)
        .then(({ data }) => {
          this.talk = data;
        });
    },
  },
  created() {
    this.fetchTalk();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: {
    Loading,
  },
};
</script>

<style>
</style>